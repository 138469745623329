<template>
  <div class="d-flex justify-content-center mb-5">
    <div v-if="eventData && eventData._id" class="event-details-wrapper" style="min-height: 90vh; max-width: 1024px; background-color: #f1f1f1;">
      <EventDescription :event-data="eventData" style="margin-bottom: 100px;" />
      <div class="d-flex justify-content-center mb-2">
        <b-button
          :to="{ name: 'client-reserve-event', params: { id: $route.params.id } }"
          size="lg"
          variant="danger"
        >
          <span style="font-weight: 600; font-size: large;">Register Now</span>
        </b-button>
      </div>
      <EventSpeakers :event-data="eventData" />
    </div>
  </div>
</template>

<script>
import {
  BOverlay, BAlert, BFormInput, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BLink, BFormGroup, BInputGroup, BInputGroupAppend,
  BCard, BRow, BCol, BBadge, BCardText, BDropdown, BDropdownItem, BForm, BFormTextarea, BFormCheckbox, BButton, BCardBody, BProgress, BProgressBar
} from 'bootstrap-vue'

import { get } from "lodash";
import { kFormatter } from '@core/utils/filter'

import Ripple from 'vue-ripple-directive'
import VueCountdown from '@chenfengyuan/vue-countdown';
import ListEvents from "@/@core/components/shared/events/ListEvents.vue"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EventSpeaker from "@/@core/components/shared/events/EventSpeaker.vue";
import EventMainSpeaker from "@/@core/components/shared/events/EventMainSpeaker.vue";
import EventCountDown from "@/@core/components/shared/events/EventCountDown.vue";
import EventDescription from "@/@core/components/shared/events/EventDescription.vue";
import EventSpeakers from "@/@core/components/shared/events/EventSpeakers.vue";

export default {
  components: {
    BOverlay,
    BAlert,
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BCardBody,
    VueCountdown,
    BProgress, 
    BProgressBar,
    EventSpeaker,
    EventMainSpeaker,
    EventCountDown,
    EventDescription,
    EventSpeakers,

    ListEvents
  },
  directives: {
    Ripple,
  },
  props: {
    eventData: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      loading: false,
      recentEvents: [],
    }
  },
  computed: {
    getCountDownTime() {
      const start_date = this.eventData.event_start_date;
      const ms = Date.parse(start_date) - Date.now();
      return ms
    },
    slotsAvailable() {
      if (!this.eventData.require_accommodation){
        return Infinity
      }

      const accommodations = this.getValueFromSource(this.eventData, 'accommodations', []);
      const total_slots = accommodations.reduce((a, c) => a + (c.number_of_persons_per_room * c.number_of_rooms), 0);
      const total_reservations = accommodations.reduce((a, c) => a + c.reservations, 0);

      return total_slots - total_reservations
    }
  },
  watch: {
    'eventData._id': {
      handler(v){
        if (v){
          this.fetchRecentEvents();
        }
      }, 
      immediate: true
    }
  },
  methods: {
    kFormatter,
    async fetchRecentEvents() {
      try {
        this.loading = true; 

        const request = await this.useJwt().sharedService.fetchRecentEvents(this.eventData._id);
        const { data } = request.data;

        this.recentEvents = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    isAccommodationFull(accommodation) {
      return accommodation.reservations === (accommodation.number_of_rooms * accommodation.number_of_persons_per_room)
    }
  },
}
</script>

<style lang="scss">
$pensa-blue: #031989;

.text-pensa-blue {
  color: $pensa-blue;
}

#main-event {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bg-pensa-blue {
  background-color: $pensa-blue;
}

.underliner {
  height: 2.5px;
  background-color: #a2aba6;
}

.event-details-wrapper {
  .font-lg {
    font-size: 50px;
  }

  p {
    font-size: 14px;
  }

  .up-label {
    border-bottom: 5px solid rgb(2, 2, 119);
    padding-bottom: 10px;
    text-transform: uppercase;
  }

  .event-activity-wrapper {
    box-shadow: 0 0 8px 0 rgba(black, 0.14);
    border-radius: 10px;

    .activity-time-container {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      background-color: black;

      .activity-time {
        font-size: 18px;
      }
    }
  }

  .event-type {
    border: 1px solid white;
    padding: 1px 5px 1px 5px;
  }

  .banner {
    position: relative;
    height: 500px;
    .b-thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: grayscale(1);
    }
    overflow: hidden;

    .event-overlay {
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.8);
      position: absolute;
      top: 0;
      left: 0;

      .count-down {
        border: 2px solid white;
        width: 70px;
        height: 40px;
        font-weight: bold;
        line-height: 40px;
        font-size: 25px;
        color: white;
        text-align: center;
        border-radius: 5px;
        margin-right: 5px;
      }
      .bar {
        border-bottom: 5px solid white;
        width: 200px;
        height: 1px;
      }
    }
  }

  /* waves */
  .ocean {
    height: 80px; /* change the height of the waves here */
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
  }

  .wave {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23fff'/%3E%3C/svg%3E");
    position: absolute;
    width: 200%;
    height: 100%;
    animation: wave 10s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
  }

  @keyframes wave {
    0% {transform: translateX(0);}
    50% {transform: translateX(-25%);}
    100% {transform: translateX(-50%);}
  }

  .slots-container {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #000e5c;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      font-size: 18px;
    }
  }
  .slots-container.danger {
    background-color: darkred;
  }
}
</style>
