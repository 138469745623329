<template>
  <div class="count-down-days">
    <h1 class="text-center" style="color: #ae0803; font-size:100px;">{{ daysUntilEvent }}</h1>
    <h1 class="text-center" style="font-weight: bold;">DAYS MORE</h1>
  </div>
</template>
<script>
import { BImg } from "bootstrap-vue";
import { differenceInDays } from "date-fns";

export default {
  components: {
    BImg,
  },
  props: {
    startDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    daysUntilEvent() {
        return differenceInDays(new Date(this.startDate), new Date())
    }
  }
};
</script>

<style scoped lang="scss">
.count-down-days {
  max-width: 200px;
  width: 200px;
  height: 200px;
  background-color: #dfdfdf;
  border-radius: 20px;

  @media (max-width: 576px) {
    display: none;
  }
}
</style>
