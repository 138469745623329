<template>
  <div class="main-speaker-container">
    <b-img 
      :src="getValueFromSource(speaker, 'avatar.path')"
      width="200"
      height="220"
      style="border-radius: 10px;"
    />
    <div class="designation">
      <h5 class="m-0" style="color: #f6ef7a;">{{ speaker.name }}</h5>
      <h5 class="m-0" style="color: white;">{{ speaker.designation }}</h5>
      <h5 class="m-0" style="color: white;">{{ speaker.church }}</h5>
    </div>
  </div>
</template>
<script>
import { BImg } from "bootstrap-vue";

export default {
  components: {
    BImg,
  },
  props: {
    speaker: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.main-speaker-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.designation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #031989;
  color: white;
  padding: 10px;
  border-radius: 10px;

  @media (max-width: 576px) {
    padding: 5px;
    width: 65vw;
  }
}
</style>
