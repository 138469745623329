<template>
  <b-overlay :show="loading">
      <b-alert
        variant="danger"
        :show="eventData === undefined && !loading"
      >
        <h4 class="alert-heading">
          Error fetching event details
        </h4>
        <div class="alert-body">
          <b-link
            class="alert-link"
            :to="{ name: 'events'}"
          >
            List Events
          </b-link>
        </div>
      </b-alert>

      <event-details v-if="eventData" :event-data="eventData" @refresh="fetchEvent" />
    </b-overlay>
</template>

<script>
import {
  BOverlay, BAlert, BFormInput, BMedia, BMediaAside, BMediaBody, BImg, BLink,
  BCard, BRow, BCol, BBadge, BCardText, BButton
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import { get } from "lodash"

import EventDetails from "@/@core/components/shared/events/EventDetails.vue"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'

export default {
  components: {
    BOverlay,
    BAlert,
    BFormInput,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BImg,
    BBadge,
    BButton,
    BCardText,
    ContentWithSidebar,

    EventDetails
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      eventData: {},
      loading: false,
    }
  },
  computed: {},
  created() {
    this.fetchEvent();
  },
  methods: {
    async fetchEvent() {
      try {
        this.loading = true; 

        const { id } = this.$route.params;

        const request = await this.useJwt().sharedService.fetchEvent(id);
        const { data } = request.data;

        this.eventData = data;
      } catch (error) {
        this.eventData = undefined
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    kFormatter,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
