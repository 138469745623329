<template>
  <div class="event-description-container">
    <div class="d-flex flex-col justify-content-center">
      <b-img
        fluid
        alt="event-image"
        :src="getValueFromSource(eventData, 'flyer_image.path')"
        :height="500"
        :width="1024"
        style="object-fit: cover; max-height: 500px; width: 100%;"
        class="img-fluid"
      />
    </div>
    <div id="event-details" class="event-detail-card">
      <div class="event-detail-card-top">
        <div>
          <h3 style="font-weight: bold;">Date</h3>
          <div class="divider"></div>
          <h3 class="text-primary">
            {{ formatDuration(eventData.event_start_date, eventData.event_end_date) }}
          </h3>
        </div>
        <div>
          <h3 style="font-weight: bold;">Reporting Time</h3>
          <div class="divider"></div>
          <h4 class="text-primary">
            {{ formatTime(eventData.event_start_time) }} -
            {{ formatTime(eventData.event_end_time) }}
          </h4>
        </div>
        <div class="d-sm-none">
          <h1>Days Remaining</h1>
          <div class="divider"></div>
          <h2>
            {{ daysUntilEvent }}
          </h2>
        </div>
      </div>
      <div
        class="event-detail-card-bottom"
        style=""
      >
        <h3 style="font-weight: bold;">Event Centers</h3>
        <div
          style="height: 3px; margin-bottom: 5px;"
          class="bg-pensa-blue"
        ></div>
        <div class="event-centers">
          <p v-for="eventCenter of eventCenters" :key="eventCenter">{{ eventCenter }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue';
import { differenceInDays } from "date-fns"
import { uniq } from "lodash"

export default {
  components: {
    BImg
  },
  props: {
    eventData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    daysUntilEvent() {
      return differenceInDays(new Date(this.eventData.event_start_date), new Date())
    },
    eventCenters() {
      return uniq(this.eventData.accommodations.reduce((prev, curr) => [...prev, this.getValueFromSource(curr, 'event_center.title') ], []))
    }
  }
};
</script>

<style scoped lang="scss">
.event-description-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.event-detail-card {
  max-width: 700px;
  position: absolute;
  left: 21%;
  bottom: -15%;
  min-width: 60%;

  @media (max-width: 576px) {
    width: 100%;
    position: static;
    border-radius: 0px;
    margin-top: 5px;
  }
}

.event-detail-card-top {
  display: flex;
  gap: 100px;
  background-color: #bfc3c2;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media (max-width: 576px) {
    flex-direction: column;
    gap: 15px;
    width: 100%;
    padding: 20px;
  }
}

.event-detail-card-bottom {
  background-color: #d5d5d5;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;

  @media (max-width: 576px) {
    padding: 20px;
  }
}

.event-centers {
  display: flex;
  justify-content: space-between;
  @media (max-width: 576px) {
    flex-direction: column;
    width: 100%;
  }
}

$pensa-blue: #031989;

.text-pensa-blue {
  color: $pensa-blue;
}

.bg-pensa-blue {
  background-color: $pensa-blue;
}

.divider {
  height: 2.5px;
  background-color: #a2aba6;
  @media (max-width: 576px) {
    background-color: $pensa-blue;
  }
}
</style>
