<template>
  <div class="speaker-container">
    <b-img height="170" width="150" :src="getValueFromSource(speaker, 'avatar.path')" style="border-radius: 10px;" />
    <div>
      <h5 class="text-center m-0">{{ speaker.name }}</h5>
      <h5 class="text-center m-0">{{ speaker.designation }}</h5>
    </div>
  </div>
</template>
<script>
import { BImg } from "bootstrap-vue"

export default {
  components: {
    BImg
  },
  props: {
    speaker: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.speaker-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 150px;
  border-radius: 10px;
  gap: 5px;
}
</style>
