<template>
  <div id="main-event">
    <b-row
      id="speakers stuff"
      style="display: flex; width: 100%; padding-left: 70px; padding-right: 70px;"
    >
      <b-col id="main-speaker-and-countdown" md="4" sm="12">
        <h1 style="font-weight: 900;">SPEAKERS</h1>
        <EventMainSpeaker
          :speaker="eventData.main_speaker_data"
        />
        <EventCountDown class="mt-5" :start-date="eventData.event_start_date" />
      </b-col>
      <b-col md="8" sm="12">
        <div class="speaker-divider my-1"></div>
        <div class="speakers-grid">
          <EventSpeaker
            v-for="(speaker, index) in eventData.speakers_data"
            :key="index"
            :speaker="speaker"
          />
        </div>
        <div
          v-if="eventData.contacts && eventData.contacts !== 'undefined'"
          id="contacts-and-enquiries "
          class="d-flex flex-column justify-content-center align-items-center flex-grow w-100"
          style="width: 100% !important;"
        >
          <p class="text-primary" style="font-weight: bold; font-size: 14px;">For Enquiries Contact the Numbers Below</p>
          <h3 class="mb-3" style="font-size: 16px; font-weight: bold;">{{ eventData.contacts }}</h3>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BOverlay,
  BAlert,
  BFormInput,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BButton,
  BCardBody,
  BProgress,
  BProgressBar,
} from "bootstrap-vue";

import { get } from "lodash";
import { kFormatter } from "@core/utils/filter";

import Ripple from "vue-ripple-directive";
import VueCountdown from "@chenfengyuan/vue-countdown";
import ListEvents from "@/@core/components/shared/events/ListEvents.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EventSpeaker from "@/@core/components/shared/events/EventSpeaker.vue";
import EventMainSpeaker from "@/@core/components/shared/events/EventMainSpeaker.vue";
import EventCountDown from "@/@core/components/shared/events/EventCountDown.vue";

export default {
  components: {
    BOverlay,
    BAlert,
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BCardBody,
    VueCountdown,
    BProgress,
    BProgressBar,
    EventSpeaker,
    EventMainSpeaker,
    EventCountDown,

    ListEvents,
  },
  directives: {
    Ripple,
  },
  props: {
    eventData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      recentEvents: [],
    };
  },
  computed: {
    getCountDownTime() {
      const start_date = this.eventData.event_start_date;
      const ms = Date.parse(start_date) - Date.now();
      return ms;
    },
    slotsAvailable() {
      if (!this.eventData.require_accommodation) {
        return Infinity;
      }

      const accommodations = this.getValueFromSource(
        this.eventData,
        "accommodations",
        []
      );
      const total_slots = accommodations.reduce(
        (a, c) => a + c.number_of_persons_per_room * c.number_of_rooms,
        0
      );
      const total_reservations = accommodations.reduce(
        (a, c) => a + c.reservations,
        0
      );

      return total_slots - total_reservations;
    },
  },
  watch: {
    "eventData._id": {
      handler(v) {
        if (v) {
          this.fetchRecentEvents();
        }
      },
      immediate: true,
    },
  },
  methods: {
    kFormatter,
    async fetchRecentEvents() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchRecentEvents(
          this.eventData._id
        );
        const { data } = request.data;

        this.recentEvents = data;
      } catch (error) {
        const error_message =
          get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    isAccommodationFull(accommodation) {
      return (
        accommodation.reservations ===
        accommodation.number_of_rooms * accommodation.number_of_persons_per_room
      );
    },
  },
};
</script>

<style lang="scss">
$pensa-blue: #031989;

.text-pensa-blue {
  color: $pensa-blue;
}

.speaker-divider {
  background-color: $pensa-blue;
  height: 2.5px;
  // width: 100%;
  // margin-right: 0px;
  // padding-right: 0px;

  @media (max-width: 576px) {
    display: none;
  }
}

#main-event {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#main-speaker-and-countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
}

.bg-pensa-blue {
  background-color: $pensa-blue;
}

.speakers-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 100px;
    // max-width: 906px;
}

.speakers-grid {
    
    @media (min-width: 576px) {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }

  @media (max-width: 576px) {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
  }
}
</style>
